 

<template>
  <div class="Complaint">
    <table class="comment">
      <tr class="title">
        <td style="width:40%">商品详情</td>
        <td style="width:40%">状态</td>
        <td style="width:20%">操作</td>

      </tr>

      <tr
        v-for='(item,index) in list'
        :key='index'
        class="item"
      >
        <td>
          <div class='goods'>
            <img
              style=" width:100px;height:100px"
              :src='$config.baseUrl+item.files_path'
            >
            <dl>

              <dt>{{item.goodname}} </dt>
              <dd>{{item.shopname}}</dd>
            </dl>
          </div>
        </td>
        <td>
          <dl class="content">
            <dt>{{item.content}} </dt>
            <dd>
              <span
                v-for='(i,idx) in item.gallery '
                :key='idx'
                style="margin-right:10px"
              >
                <img :src="i">

              </span>

            </dd>
            <dd>{{item.add_date}}</dd>
          </dl>

        </td>

        <td
          :span="6"
          class="handle"
        >
          <span
            @click="evaluate(item.order_id,0)"
            class="hover"
          > 查看评价</span>
        </td>
      </tr>
    </table>
  </div>
</template>
<script type="text/javascript">
export default {
  data() {
    return { list: [] };
  },
  methods: {
      
    evaluate(id, num) {
      this.$router.push({
        path: "/member-order-evaluate",
        query: { id: id, num: num }
      });
    },
    //查询评论列表
    getComment() {
      this.$get("home/comment", {
        member_id: this.$store.state.member.id
      }).then(res => {
        this.list = res.items.data;
        this.list.forEach(item => {
          item.gallery = item.gallery.split(",");
        });

        console.log("111", this.list);
      });
    }
  },
  components: {},
  created() {
    this.getComment();
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/mixins";

.goods {
  dl {
    flex: 1;
    margin-left: 10px;
    box-sizing: border-box;
  }
  @include space-between;
}
.handle {
  text-align: center;
}
.comment {
  width: 100%;
  border-collapse: collapse;
  td {
    border-collapse: collapse;
    padding: 10px;
    border: 1px solid #eee;
  }
  .title {
    background: #eee;
    text-align: center;
    line-height: 30px;
  }
  .item {
    dl {
      dt {
        margin-bottom: 10px;
      }
      dd {
        margin-bottom: 10px;
      }
    }
  }
}
.content {
  img {
    width: 50px;
    height: 50px;
  }
}
</style>


